import * as React from 'react'

import * as styles from '@/components/Luxury/Slide/Main.module.scss'
import '@/styles/swiper.scss'
import Parallelogram from '@/components/Path/Parallelogram'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
import { StaticImage } from 'gatsby-plugin-image'

const LuxurySlider = () => {
  return (
    <section className={styles['main']}>
      <div className={styles['container']}>
        <Parallelogram />
        <Swiper
          className='slider-hostel'
          grabCursor={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          speed={1000}
          loop={true}
          navigation={true}
          slidesPerView={'auto'}
          centeredSlides={true}
          modules={[Autoplay, Navigation]}
        >
          <SwiperSlide>
            <StaticImage
              className={styles['image']}
              src='../../../images/luxury/slide1.jpg'
              alt='Luxury-1'
              placeholder='blurred'
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              className={styles['image']}
              src='../../../images/luxury/slide2.jpg'
              alt='Luxury-2'
              placeholder='blurred'
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              className={styles['image']}
              src='../../../images/luxury/slide3.jpg'
              alt='Luxury-3'
              placeholder='blurred'
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              className={styles['image']}
              src='../../../images/luxury/slide4.jpg'
              alt='Luxury-4'
              placeholder='blurred'
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}

export default LuxurySlider
