import * as React from 'react'

import * as styles from '@/components/Luxury/Entry/Entry.module.scss'

const LuxuryEntry = () => {
  return (
    <section className={styles['entry']}>
      <h3 className={styles['title']}>
        <ruby>櫓<rt className={styles['ruby']}>やぐら</rt>
        </ruby>
        や照明をレンタルできます。
        <br />
        特別な空間演出にぜひご利用ください。
      </h3>
      <a className={styles['button']} href='mailto:latempo2022@gmail.com'>
        オプション相談　→
      </a>
    </section>
  )
}

export default LuxuryEntry
