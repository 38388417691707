import * as React from 'react'

import * as styles from '@/components/Luxury/Thanks/Main.module.scss'

const LuxuryThanks = () => {
  return (
    <section className={styles['main']}>
      <p className={styles['title']}>
        今回、丸岡の
        <a href='https://ricka-yukinohana.com/' className={styles['url']}>
          むつのはな
        </a>
        、五十嵐シェフにご協力いただき、
        <br />
        素敵な料理をご提供頂きました。
        <br />
      </p>
    </section>
  )
}

export default LuxuryThanks
