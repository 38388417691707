// extracted by mini-css-extract-plugin
export var animLine = "Main-module--animLine--sMgfR";
export var container = "Main-module--container--dJ6Fq";
export var fadeDown = "Main-module--fadeDown--lNjdv";
export var fadeUp = "Main-module--fadeUp--GBbU4";
export var flowing = "Main-module--flowing--GGnli";
export var image = "Main-module--image--ewCpi";
export var imageWrap = "Main-module--imageWrap--fKOLo";
export var lineIn = "Main-module--lineIn--JfSvp";
export var main = "Main-module--main--lQlX0";
export var title = "Main-module--title--W+UYe";