// extracted by mini-css-extract-plugin
export var animLine = "Main-module--animLine--EL7nB";
export var container = "Main-module--container--Ngo-F";
export var fadeDown = "Main-module--fadeDown--Widtc";
export var fadeUp = "Main-module--fadeUp--iqW2N";
export var flowing = "Main-module--flowing--JvmZi";
export var image = "Main-module--image--BJKvt";
export var imageWrap = "Main-module--imageWrap--JgcsM";
export var lineIn = "Main-module--lineIn--eCqc5";
export var main = "Main-module--main--75YAD";
export var title = "Main-module--title--qUZkV";