// extracted by mini-css-extract-plugin
export var background = "Main-module--background--gyqpM";
export var container = "Main-module--container--aLsa7";
export var description = "Main-module--description--1ipe4";
export var fadeDown = "Main-module--fadeDown--a9vs7";
export var fadeUp = "Main-module--fadeUp--SGgVK";
export var flowing = "Main-module--flowing--ge2hG";
export var font = "Main-module--font--DiCm2";
export var lineIn = "Main-module--lineIn--jJ5tH";
export var main = "Main-module--main--HLzHd";
export var title = "Main-module--title--iEl7h";