import * as React from 'react'

import * as styles from '@/components/Luxury/Video/Main.module.scss'

const LuxuryVideo = () => {
  return (
    <div className={styles['main']}>
      <iframe
        className={styles['video']}
        src={`https://www.youtube.com/embed/yjZtp3IuyIQ`}
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default LuxuryVideo
