import * as React from 'react'

import * as styles from '@/components/Luxury/Images/Main.module.scss'
import { useIntersection } from '@/hooks/useIntersection'
import { StaticImage } from 'gatsby-plugin-image'

type Props = {
  desc1: string
  desc2: string
  src: Number
}

const LuxuryImages: any = ({ desc1, desc2, src }: Props) => {
  let image
  switch (src) {
    case 1: {
      image = (
        <StaticImage
          className={styles['image']}
          src={`../../../images/luxury/image1.png`}
          alt='Images'
          placeholder='blurred'
        />
      )
      break
    }
    case 2: {
      image = (
        <StaticImage
          className={styles['image']}
          src={`../../../images/luxury/image2.png`}
          alt='Images'
          placeholder='blurred'
        />
      )
      break
    }
    case 3: {
      image = (
        <StaticImage
          className={styles['image']}
          src={`../../../images/luxury/image3.png`}
          alt='Images'
          placeholder='blurred'
        />
      )
      break
    }
    case 4: {
      image = (
        <StaticImage
          className={styles['image']}
          src={`../../../images/luxury/image4.png`}
          alt='Images'
          placeholder='blurred'
        />
      )
      break
    }
    case 5: {
      image = (
        <StaticImage
          className={styles['image']}
          src={`../../../images/luxury/image5.png`}
          alt='Images'
          placeholder='blurred'
        />
      )
      break
    }
    case 6: {
      image = (
        <StaticImage
          className={styles['image']}
          src={`../../../images/luxury/image6.png`}
          alt='Images'
          placeholder='blurred'
        />
      )
      break
    }
    case 7: {
      image = (
        <StaticImage
          className={styles['image']}
          src={`../../../images/luxury/title.png`}
          alt='Images'
          placeholder='blurred'
        />
      )
      break
    }
  }
  const titleRef = React.useRef<HTMLHeadingElement>(null)
  const viewTitle = useIntersection({ target: titleRef, margin: '-40% 0%' })

  return (
    <section className={styles['main']}>
      <div className={styles['container']}>
        <div
          className={`${styles['title']} ${
            viewTitle ? styles['animLine'] : undefined
          }`}
          ref={titleRef}
        >
          {desc2}
          <br />
          {desc1}
        </div>
        <div className={`${styles['imageWrap']}`}>{image}</div>
      </div>
    </section>
  )
}

export default LuxuryImages
